import { MDBBtn } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import Register from './Register';
import RegisterPrice from './RegisterPrice';

export default function RegisterConfirmation() {
    return <Register step={3}>
    <div className='my-4 d-flex flex-column gap-4'>
        <p className='text-center m-0'>Order summary</p>
        <RegisterPrice gasBalance/>
        <MDBBtn size='lg' color={'primary'} rounded>{texts.submitRegister}</MDBBtn>
    </div>
</Register>
}