import { MDBCard, MDBCardBody, MDBCardHeader, MDBCardTitle, MDBCol, MDBIcon } from "mdb-react-ui-kit";

export type PanelProps = {
    className?: string
    bodyClassName?:string
    title: string
    icon: string
    onBack?(): void
    children: JSX.Element[] | JSX.Element
}

export default function Panel({ className, bodyClassName, title, icon, children, onBack }: PanelProps) {
    return <>
        <MDBCol size={12}>
            <MDBCard className={className || 'max-w900'}>
                <MDBCardHeader background='primary'>
                    <MDBCardTitle className='d-flex justify-content-between align-items-center my-2' tag='div'>
                        {onBack ? <MDBIcon color="light" fas icon='arrow-left' size='2x' className="pointer" onClick={onBack} /> : <span></span>}
                        <div className="d-flex justify-content-center align-items-center gap-3">
                            <MDBIcon color="light" fas icon={icon} size='2x' />
                            <h5 className='m-0 text-light'>{title}</h5>
                        </div>
                        {onBack ? <MDBIcon color="light" fas icon='arrow-left' size='2x' className="invisible" onClick={onBack} /> : <span></span>}
                    </MDBCardTitle>
                </MDBCardHeader>
                <MDBCardBody className={`d-flex flex-column ${bodyClassName}`}>
                    {children}
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    </>
}