import React from 'react';
import { Container, Image } from 'react-bootstrap';

export type EmptyResultProps = {
    title: string
    image?:string
}

export default function EmptyResult({ title, image }: EmptyResultProps) {
    return <Container className="d-flex align-content-center align-items-center justify-content-center">
    <Container className="d-flex flex-column max-w400 text-center align-items-center">
        <div className="max-w200 my-2 ratio ratio-1x1 d-flex align-items-center justify-content-center">
            {/*<FontAwesomeIcon style={{ position: "initial" }} className="fa-6x text-light" icon={faPencilRuler} />*/}
            <Image src={image??"/assets/images/empty.gif"}/>
        </div>
        <Container as="h2" className="text-uppercase">{title}</Container>
    </Container>
</Container>
}
