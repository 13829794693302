import axios from "axios";
import { UnitUtils } from "../utils";
import { ConfigService } from "./configService";

export class SwapService {
    private _BASE_URL = 'https://api.1inch.exchange/v4.0/';
    constructor(private configService: ConfigService, private forceChain?: number) { }

    get baseUrl() {
        if (this.forceChain) {
            return this._BASE_URL + this.forceChain;
        }
        return this._BASE_URL + this.configService.getCurrentChainId();
    }

    async getApproveAddress(): Promise<ApproveSpender> {
        return this.wrap(async () => {
            const url = `${this.baseUrl}/approve/spender`;
            const result = await axios.get(url);
            return result.data;
        });
    }

    async getApproveAllowance(walletAddress: string, tokenAddress: string): Promise<ApproveAllowance> {
        return this.wrap(async () => {
            const url = `${this.baseUrl}/approve/allowance`;
            const result = await axios.get(url, { params: { tokenAddress, walletAddress } });
            return result.data;
        });
    }

    async getHealthCheck(): Promise<boolean> {
        return this.wrap(async () => {
            const url = `${this.baseUrl}/healthcheck`;
            const result = await axios.get(url);
            const data: HealthCheck = result.data;
            return data.status === "OK";
        });
    }

    async getListLiquiditySource(): Promise<LiquiditySources> {
        return this.wrap(async () => {
            const url = `${this.baseUrl}/liquidity-sources`;
            const result = await axios.get(url);
            const data: LiquiditySources = result.data;
            return data;
        });
    }

    _cacheTokens?: Tokens;

    async getListTokens(): Promise<Tokens> {
        return this.wrap(async () => {
            if (this._cacheTokens) return this._cacheTokens!;
            const url = `${this.baseUrl}/tokens`;
            const result = await axios.get(url);
            result.data.tokens = Object.values(result.data.tokens);
            const data: Tokens = result.data;
            this._cacheTokens = data;
            return data;
        })
    }

    async getPreset(): Promise<Preset> {
        return this.wrap(async () => {
            const url = `${this.baseUrl}/presets`;
            const result = await axios.get(url);
            const data: Preset = result.data;
            return data;
        });
    }

    async getQuote(unsafe: QuoteCustomArg): Promise<QuoteExt> {
        return this.wrap(async () => {
            const { fromTokenAddress, toTokenAddress, amount: unsafeAmount, ...others } = unsafe;
            const amount = UnitUtils.addPrecision(unsafeAmount, fromTokenAddress.decimals).toString();
            const safeArgs: QuoteArg = { ...others, amount, fromTokenAddress: fromTokenAddress.address, toTokenAddress: toTokenAddress.address }
            const url = `${this.baseUrl}/quote`;
            const result = await axios.get(url, { params: safeArgs });
            const data: Quote = result.data;
            return { ...data, estimatedGasCoin: UnitUtils.removePrecisionStrFromNum(data.estimatedGas, 6) };
        });
    }

    async getSwapTransaction(unsafe: SwapCustomArg): Promise<Swap> {
        return this.wrap(async () => {
            const { fromTokenAddress, toTokenAddress, amount: unsafeAmount, ...others } = unsafe;
            const amount = UnitUtils.addPrecision(unsafeAmount, fromTokenAddress.decimals).toString();
            const safeArgs: SwapArg = { ...others, amount, fromTokenAddress: fromTokenAddress.address, toTokenAddress: toTokenAddress.address }
            const url = `${this.baseUrl}/swap`;
            const result = await axios.get(url, { params: safeArgs });
            const data: Swap = result.data;
            return data;
        });
    }

    private async wrap<T>(callback: () => Promise<T>): Promise<T> {
        try {
            const tmp = await callback();
            return tmp;
        } catch (e) {
            if ((e as any).response && (e as any).response.data) {
                throw (e as any).response.data;
            } else {
                throw e;
            }
        }
    }

}
export type  Swap = {
    "fromToken": {
        "symbol": string,
        "name": string,
        "address": string,
        "decimals": number,
        "logoURI": string
    },
    "toToken": {
        "symbol": string,
        "name": string,
        "address": string,
        "decimals": number,
        "logoURI": string
    },
    "toTokenAmount": string,
    "fromTokenAmount": string,
    "protocols": string[],
    "tx": {
        "from": string,
        "to": string,
        "data": string,
        "value": string,
        "gasPrice": string,
        "gas": string
    }
}
export type  QuoteCustomArg = Omit<Omit<QuoteArg, "fromTokenAddress">, "toTokenAddress"> & {
    fromTokenAddress: Token
    toTokenAddress: Token
}
export type  SwapCustomArg = Omit<Omit<SwapArg, "fromTokenAddress">, "toTokenAddress"> & {
    fromTokenAddress: Token
    toTokenAddress: Token
}
export type  SwapArg = {
    fromTokenAddress: string;
    toTokenAddress: string
    amount: string | number;
    fromAddress: string;
    slippage: number //  min: 0; max: 50;
    //optional
    protocols?: "all" | string //default all
    destReceiver?: string //default fromAddress
    referrerAddress?: string
    fee?: "0" | "1" | "2" | "3" //default 0; max 3
    gasPrice?: string // default fast
    disableEstimate?: boolean
    burnChi?: boolean//deafault false
    allowPartialFill?: boolean
    virtualParts?: number // default: 40; max: 500;
    parts?: number //default: 40; max: 100
    mainRouteParts?: number //default 10; max 50 
    connectorTokens?: number //max: 5
    complexityLevel?: "0" | "1" | "2" | "3" //default 2; max 3
    gasLimit?: string
}
export type  QuoteArg = {
    fromTokenAddress: string,
    toTokenAddress: string,
    amount: string | number,
    //optional
    protocols?: "all" | string //default all
    fee?: "0" | "1" | "2" | "3" //default 0; max 3
    gasLimit?: string
    connectorTokens?: number //max: 5
    complexityLevel?: "0" | "1" | "2" | "3" //default 2; max 3
    mainRouteParts?: number //default 10; max 50 
    virtualParts?: number // default: 40; max: 500;
    parts?: number //default: 40; max: 100
    gasPrice?: string // default fast
}
export type  Quote = {
    "fromToken": {
        "symbol": string,
        "name": string,
        "address": string,
        "decimals": number,
        "logoURI": string
    },
    "toToken": {
        "symbol": string,
        "name": string,
        "address": string,
        "decimals": number,
        "logoURI": string
    },
    "toTokenAmount": string,
    "fromTokenAmount": string,
    "protocols": [
        {
            "name": string,
            "part": number,
            "fromTokenAddress": string,
            "toTokenAddress": string
        }
    ],
    "estimatedGas": number
}
export type  QuoteExt = Quote & {
    "estimatedGasCoin": string
}
export type  Preset = {
    "MAX_RESULT": [
        {
            "complexityLevel": number,
            "mainRouteParts": number,
            "parts": number,
            "virtualParts": number
        }
    ],
    "LOWEST_GAS": [
        {
            "complexityLevel": number,
            "mainRouteParts": number,
            "parts": number,
            "virtualParts": number
        }]
}
export type  Token = {
    "symbol": string,
    "name": string,
    "address": string,
    "decimals": number,
    "logoURI": string
}
export type  Tokens = {
    "tokens": Token[]
}
export type  LiquiditySource = {
    "id": string,
    "title": string,
    "img": string
}
export type  LiquiditySources = {
    "protocols": LiquiditySource[]
}
export type  HealthCheck = {
    "status": "OK" | "NOK"
}
export type  ApproveAllowance = {
    "allowance": string
}
export type  ApproveSpender = {
    "address": string
}
export type  ApproveTransaction = {
    "data": string,
    "gasPrice": string,
    "to": string,
    "value": string
}