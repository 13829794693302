import { MDBContainer, MDBListGroup } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { texts } from "../constants/text";
import { Balance, vaultService } from "../services";
import InputSearch from "../ui/InputSearch";
import Loader from "../ui/Loader";
import AccountGasRow from "./AccountGasRow";
import AccountRow from "./AccountRow";
import Dashboard from "../dashboard/dashboard";

export type AccountState = {
    loading: boolean
    balances?: Balance[],
    gas?: Balance
}

export default function Accounts() {
    const [state, setState] = useState<AccountState>({ loading: true });
    //subscribe
    const reload = async () => {
        setState((state) => ({ ...state, loading: true }));
        const gas = await vaultService.getGasBalance();
        const balances = await vaultService.getBalances();
        setState((state) => ({ ...state, balances, gas, loading: false }));
    }
    useEffect(() => {
        reload();
    }, []);
    const { loading, balances, gas } = state;
    if (loading) {
        return <Dashboard active="accounts" className="max-w800">
            <Loader />
        </Dashboard>
    }
    return <Dashboard active="accounts" className="max-w800">
        <MDBContainer className='d-flex gap-2 flex-column pb-4' fluid>
            <small>{texts.gasAccount}</small>
            <AccountGasRow balance={gas} />
            <div className="d-flex justify-content-between align-items-baseline mt-3">
                <small>{texts.cryptoAccount}</small>
                <InputSearch label={texts.searchByName} className="max-w300 flex-grow-1" />
            </div>
            <MDBListGroup tag='div'>
                {balances?.map((e,i) => {
                    return <AccountRow balance={e} key={i} />
                })}
            </MDBListGroup>
        </MDBContainer>
    </Dashboard>
}