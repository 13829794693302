import { MDBBtn } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import Register from './Register';

export default function RegisterGas() {
    return <Register step={2}>
        <div className='my-4 d-flex flex-column gap-4'>
            <p className='m-0'>{texts.registrationGas}</p>
            <div className='d-flex flex-column gap-1'>
                <small className='text-center'>{texts.registrationGasCB}</small>
                <MDBBtn size='lg' color={'primary'} rounded>BUY USING CREDIT CARD</MDBBtn>
            </div>
            <div className='d-flex flex-column gap-1'>
                <small className='text-center'>{texts.registrationGasDeposit}</small>
                <MDBBtn size='lg' color={'primary'} rounded>DEPOSIT FROM ANOTHER WALLET</MDBBtn>
            </div>
        </div>
    </Register>
}