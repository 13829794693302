import { MDBBtn } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { InfoOutlined } from '@mui/icons-material';

export type SwapPriceProps={
    vertical?: boolean
    onClick:()=>void
}

export default function SwapPrice({vertical, onClick}:SwapPriceProps){
    const clazz = vertical?"flex-column":""
    return <MDBBtn rounded onClick={onClick} size='lg' color='muted' className={`d-flex ${clazz} flex-grow-1 justify-content-between align-items-center`}>
        <span><InfoOutlined className="me-2" />{texts.price}</span>
        <b>1 UNI = 0.567 BNB</b>
    </MDBBtn>
}