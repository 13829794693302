import { MDBBtn } from "mdb-react-ui-kit";
import { Step, StepLabel, Stepper } from '@mui/material';

export type InputSubmitProps = {
    labelText: string
}

export default function InputSubmit({ labelText }: InputSubmitProps) {
    const sx = {
        '& .Mui-completed': {
            color: 'var(--mdb-primary) !important;'
        },
        '& .Mui-active': {
            color: 'var(--mdb-primary) !important;'
        },
    };
    return <div className="d-flex flex-column gap-3 mt-4">
        <Stepper activeStep={1} color='success'>
            <Step sx={sx} key={1} color='success'> <StepLabel color="success"></StepLabel></Step>
            <Step sx={sx} key={2} > <StepLabel></StepLabel></Step>
            <Step sx={sx} key={3} > <StepLabel></StepLabel></Step>
        </Stepper>
        <MDBBtn size='lg' color={'primary'} rounded>{labelText}</MDBBtn>
    </div>
}