import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { Image } from "react-bootstrap"
import { texts } from "../constants/text";
import { Balance } from "../services";

export type AccountGasRowProps = {
    balance?: Balance
}

export default function AccountGasRow({ balance: gas }: AccountGasRowProps) {
    return <MDBListGroup tag='div'>
        <MDBListGroupItem tag='div' action className="d-flex align-items-center gap-3">
            <Image src={gas?.logo!} height={34} className="my-2" />
            <div className='d-flex flex-column flex-grow-1 min-w0'>
                <h6 className='m-0 d-flex align-items-center gap-2'>
                    <span className="h4 text-bold m-0 lh-1 text-truncate">{gas?.balance}</span>
                    {gas?.symbol}
                </h6>
                <small className="text-muted lh-1 fw-light">{gas?.name}</small>
            </div>
            <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.buy}</MDBBtn>
            <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.depositUpper}</MDBBtn>
            <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.transferUpper}</MDBBtn>
            <MDBDropdown className="d-md-none text-nowrap">
                <MDBDropdownToggle color="warning">{texts.actionsUpper}</MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem><MDBDropdownLink href="#">{texts.buy}</MDBDropdownLink></MDBDropdownItem>
                    <MDBDropdownItem><MDBDropdownLink href="#">{texts.depositUpper}</MDBDropdownLink></MDBDropdownItem>
                    <MDBDropdownItem><MDBDropdownLink href="#">{texts.transferUpper}</MDBDropdownLink></MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </MDBListGroupItem>
    </MDBListGroup>
}