import { Step, StepLabel, Stepper } from '@mui/material';
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { Image } from 'react-bootstrap';
import { texts } from '../constants/text';
import GasPrice from '../ui/GasPrice';
import InputNumber from '../ui/InputNumber';
import Panel from '../ui/Panel';

export type Auth2FAProps = {
}

export type Auth2FAState = {
    step: number
}

export default function Auth2FA(props: Auth2FAProps) {
    const [state, setState] = useState<Auth2FAState>({ step: 0 })
    const scanDone = () => {
        setState((state) => ({ ...state, step: 1 }))
    }
    const back = () => {
        setState((state) => ({ ...state, step: state.step - 1 }))
    }
    return <Panel className='max-w480' title={texts.auth2FA} icon='shield-alt'>
        <MDBContainer className='d-flex gap-4 flex-column pb-4'>
            <p className='text-center'>{texts.auth2FASubtitle}</p>
            <Stepper activeStep={state.step}>
                <Step key={0}>
                    <StepLabel>{texts.scanQRCode}</StepLabel>
                </Step>
                <Step key={1}>
                    <StepLabel>{texts.validateCode}</StepLabel>
                </Step>
            </Stepper>
            {state.step === 0 && <div className='d-flex flex-column gap-5'>
                <p className='text-center m-0'>{texts.scanQRCOdeLabel}</p>
                <Image src="https://upload.wikimedia.org/wikipedia/commons/7/78/Qrcode_wikipedia_fr_v2clean.png" />
                <div className='d-flex gap-2 justify-content-end'>
                    <MDBBtn onClick={scanDone} className="flex-grow-1">{texts.done}</MDBBtn>
                </div>
            </div>}
            {state.step === 1 && <div className='d-flex flex-column gap-4'>
                <p className='m-0'>{texts.auth6Digit}</p>
                <InputNumber max={999999} />
                <GasPrice />
                <div className='d-flex gap-2 justify-content-end'>
                    <MDBBtn className="flex-grow-1" onClick={back}>{texts.back}</MDBBtn>
                    <MDBBtn className="flex-grow-1">{texts.submit}</MDBBtn>
                </div>
            </div>}
        </MDBContainer>
    </Panel>
}