import React from 'react';
import { NavBar } from './ui/NavBar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css';
import { config } from '@fortawesome/fontawesome-svg-core'
import Swap from './swap/Swap';
import Login from './login/Login';
import Accounts from './accounts/Accounts';
import RegisterPeriod from './login/RegisterPeriod';
import RegisterGas from './login/RegisterGas';
import RegisterConfirmation from './login/RegisterConfirmation';
import NFTList from './nft/nft';
import NFTMint from './nft/nftMint';
import NFTImport from './nft/nftImport';
import Transfer from './accounts/Transfer';
import NFTTransfer from './nft/NFTTransfer';
import Deposit from './accounts/Deposit';
import NFTDeposit from './nft/NFTDeposit';
import Register2FA from './login/Register2FA';
import Login2FA from './login/Login2FA';
import History from './dashboard/History';
import Preferences from './dashboard/Preferences';
import { routes } from './constants/routes';
config.autoAddCss = false

function App() {
  return (
    <div className="App min-vh-100 d-flex flex-column">
      <NavBar />
      <section className='d-flex justify-content-center align-items-center flex-grow-1 my-5 mx-4'>
        <Router>
          <Routes>
            <Route path={routes.swap} element={<Swap />}></Route>
            <Route path={routes.account} element={<Accounts />}></Route>
            <Route path={routes.deposit} element={<Deposit />}></Route>
            <Route path={routes.transfer} element={<Transfer />}></Route>
            <Route path={routes.history} element={<History />}></Route>
            <Route path={routes.preferences} element={<Preferences />}></Route>
            <Route path={routes.nft} element={<NFTList />}></Route>
            <Route path={routes.nftMint} element={<NFTMint />}></Route>
            <Route path={routes.nftDeposit} element={<NFTDeposit />}></Route>
            <Route path={routes.nftImport} element={<NFTImport />}></Route>
            <Route path={routes.nftTransfer} element={<NFTTransfer />}></Route>
            <Route path={routes.login} element={<Login />}></Route>
            <Route path={routes.login2fa} element={<Login2FA />}></Route>
            <Route path={routes.register2fa} element={<Register2FA />}></Route>
            <Route path={routes.registerPeriod} element={<RegisterPeriod />}></Route>
            <Route path={routes.registerGas} element={<RegisterGas />}></Route>
            <Route path={routes.registerConfirm} element={<RegisterConfirmation />}></Route>
          </Routes>
        </Router>
      </section>
    </div>
  );
}

export default App;
