import { MDBCard, MDBCardBody, MDBCardTitle, MDBBtn, MDBIcon, MDBCol, MDBContainer, MDBTooltip } from 'mdb-react-ui-kit';
import { Image } from "react-bootstrap";
import { providers, web3Providers } from '../constants/constants';
import { texts } from '../constants/text';
import Divider from '@mui/material/Divider';
import { Web3ProviderInfo } from '../services';

export type LoginProps = {
    hideSocial?: boolean
    onProviderSelect?: (provider: Web3ProviderInfo) => void;
}

export type LoginState = {
}

export default function Login({ hideSocial, onProviderSelect }: LoginProps) {
    const onSelectProvider = (provider: Web3ProviderInfo) => () => {
        onProviderSelect && onProviderSelect(provider);
    }
    return <>
        <MDBCol size={12}>
            <MDBCard className='max-w480'>
                <MDBCardBody className='d-flex flex-column'>
                    <MDBCardTitle tag='div'>
                        <h5 className='m-0 text-center'>{texts.signIn}</h5>
                    </MDBCardTitle>
                    <MDBContainer className='d-flex gap-4 flex-column pb-4'>
                        {!hideSocial && <label className='text-center small'>{texts.signInSocial}</label>}
                        {!hideSocial && <MDBContainer className='d-flex justify-content-center gap-4'>
                            {providers.filter(e => e.connect).map((p, i) => {
                                return <MDBTooltip key={i} tag='span' title={`Connect with ${p.name}`}>
                                    <MDBBtn floating size='lg' tag='a' title={`Connect with ${p.name}`}>
                                        <MDBIcon fab icon={p.logo} style={{ background: p.logoColor }} />
                                    </MDBBtn>
                                </MDBTooltip>;
                            })}
                        </MDBContainer>}
                        <Divider />
                        {!hideSocial && <label className='text-center small'>{texts.signInWeb3}</label>}
                        <MDBContainer className='d-flex flex-column gap-2 justify-content-stretch'>
                            {web3Providers.map((p, i) => {
                                return <MDBBtn key={i} onClick={onSelectProvider(p)} className='text-dark d-flex gap-2 align-items-center justify-content-center w-100' color='light'>
                                    <Image src={p.image} height={24} /> {p.name}
                                </MDBBtn>;
                            })}
                        </MDBContainer>
                    </MDBContainer>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    </>
}