import {utils} from "ethers";
import { BigNumber, BigNumberish } from "ethers";

export class UnitUtils {
    static DECIMAL = 18;
    static PRECISION = BigNumber.from(10).pow(UnitUtils.DECIMAL);
    static ZER0_32 = "0x0000000000000000000000000000000000000000000000000000000000000000"
    static weiToEth(wei:BigNumberish){
        return utils.formatEther(wei);
    }

    static gweiToEth(wei:BigNumberish){
        return utils.formatUnits(wei, "gwei");
    }

    static ethToWei(wei:BigNumberish){
        return utils.parseUnits(wei.toString(), "ether");
    }

    static addPrecision(numb:BigNumberish, precision = UnitUtils.DECIMAL):BigNumber{
        //18 decimals
        return utils.parseUnits(numb.toString(), precision);
        //return BigNumber.from(toWei(numb.toString()));
        //return BigNumber.from(numb).mul(UnitUtils.PRECISION);
    }
    static addPrecisionFrom(numb:BigNumberish, precision = UnitUtils.DECIMAL):BigNumber{
        //18 decimals
        return utils.parseUnits(numb.toString(), precision);
        //return BigNumber.from(toWei(numb.toString()));
        //return BigNumber.from(numb).mul(UnitUtils.PRECISION);
    }

    static removePrecision(numb:BigNumberish):number{
        return parseFloat(utils.formatUnits(numb, UnitUtils.DECIMAL));
    }  

    static removePrecisionStr(numb:BigNumber, fixed:number=3):string{
        const tmp = utils.formatUnits(numb, UnitUtils.DECIMAL);
        return (+tmp).toFixed(fixed);
    } 

    static removePrecisionStrFromNum(numb:BigNumberish, fixed:number=3):string{
        if(typeof numb == "undefined") return "";
        const tmp = utils.formatUnits(numb, UnitUtils.DECIMAL);
        return (+tmp).toFixed(fixed);
    }

    static removePrecisionStrFromNumWithPrecision(numb:BigNumberish,precision=UnitUtils.DECIMAL, fixed:number=3):string{
        if(typeof numb == "undefined") return "";
        const tmp = utils.formatUnits(numb, precision);
        return (+tmp).toFixed(fixed);
    }
}
