import { texts } from "../constants/text";
import InputSubmit from "../ui/InputSubmit";
import InputText from "../ui/InputText";
import Panel from "../ui/Panel";



export default function NFTImport() {
    const onBack = () => {

    }
    return <Panel icon="circle-down" className="max-w480" bodyClassName="gap-3" title={texts.importNFT} onBack={onBack}>
        <InputText labelText={texts.contract}/>
        <InputText labelText={texts.nftId}/>
        <InputSubmit labelText={texts.searchNFT}/>
    </Panel>
}