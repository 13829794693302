import { MDBBtn, MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { texts } from "../constants/text";
import { NFT, vaultService } from "../services";
import EmptyResult from "../ui/EmptyResult";
import InputSearch from "../ui/InputSearch";
import Loader from "../ui/Loader";
import Dashboard from "../dashboard/dashboard";
import NFTRow from "./nftRow";

export type NFTListState = {
    loading: boolean
    list?: NFT[],
}

export default function NFTList() {
    const [state, setState] = useState<NFTListState>({ loading: true });
    //subscribe
    const reload = async () => {
        setState((state) => ({ ...state, loading: true }));
        const list = await vaultService.getNFTS();
        setState((state) => ({ ...state, list, loading: false }));
    }
    useEffect(() => {
        reload();
    }, []);
    const { loading, list } = state;
    if (loading) {
        return <Dashboard active="nft" className="max-w800">
            <Loader />
        </Dashboard>
    }
    const empty = !list || list?.length === 0;
    return <Dashboard active="nft" className="max-w800">
        <MDBContainer className='d-flex gap-2 flex-column pb-4' fluid>
            <div className="d-flex flex-column align-items-center flex-sm-row justify-content-between align-items-baseline mt-3 gap-2">
                <div className="d-flex gap-2">
                    <MDBBtn color="warning" className="text-nowrap">{texts.mintNft}</MDBBtn>
                    <MDBBtn color="warning" className="text-nowrap">{texts.importNft}</MDBBtn>
                </div>
                <InputSearch label={texts.searchNft} className="max-w300 flex-grow-1" />
            </div>
            <MDBRow className='row-cols-1 g-4 my-4'>
                {list?.map((e, i) => {
                    return <MDBCol className="d-flex justify-content-center">
                        <NFTRow nft={e} key={i} />
                    </MDBCol>
                })}
                {empty && <EmptyResult title={texts.emptyNft} />}
            </MDBRow>
        </MDBContainer>
    </Dashboard>
}