import { MDBCard, MDBCardBody, MDBCardImage, MDBCardLink, MDBCardText, MDBCardTitle, MDBCol, MDBRow } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { NFT } from "../services";

export type NFTRowProps = {
    nft?: NFT
}

export default function NFTRow({ nft }: NFTRowProps) {
    return <MDBCard className="d-flex flex-column flex-md-row">
        <MDBCardImage src={nft?.url} position='left' className="max-w300 h-100 d-none d-md-block"/>
        <MDBCardImage src={nft?.url} position='top' className="d-block d-md-none"/>
        <MDBCardBody className="flex-grow-1">
            <MDBCardTitle>#{nft?.id}</MDBCardTitle>
            <MDBCardText tag="div">
                <MDBRow tag='dl' className="mb-0">
                    <MDBCol tag='dt' sm='12'>{texts.contract}</MDBCol>
                    <MDBCol tag='dd' sm='12' className="small">{nft?.contract}</MDBCol>
                </MDBRow>
                <MDBRow tag='dl' className="mb-0">
                    <MDBCol tag='dt' sm='12'>{texts.owner}</MDBCol>
                    <MDBCol tag='dd' sm='12' className="small">{nft?.owner}</MDBCol>
                </MDBRow>
            </MDBCardText>
            <MDBCardLink href='#'>{texts.transfer}</MDBCardLink>
            <MDBCardLink href='#'>{texts.delete}</MDBCardLink>
        </MDBCardBody>
    </MDBCard>
}