import { ReplaySubject } from "rxjs";
import { SwapService } from "./swapService";



export class Web3Service {
    //replay last value on subscribe
    private networkStream = new ReplaySubject<Network>(1);
    private userProfileStream = new ReplaySubject<UserProfile | undefined>(1);
    constructor(private swapService: SwapService) { }

    get onNetworkChange() {
        return this.networkStream.asObservable();
    }

    get onUserProfileChange() {
        return this.userProfileStream.asObservable();
    }

    setNetwork(network: Network) {
        this.networkStream.next(network);
    }

    setUserProfile(userProfile: UserProfile) {
        this.userProfileStream.next(userProfile);
    }

    setProvider(provider: Provider) {
        //TODO unmock
        this.userProfileStream.next({ id: 'test', name: 'test', provider });
    }

    logout() {
        this.userProfileStream.next(undefined);
    }
}

export type Network = {
    name: string
    logo: string
    main: boolean
    chainId: number
}

export type Provider = "web3" | "github" | "youtube" |
    "facebook" | "twitter" | "google" | "instagram" |
    "telegram" | "twitch" | "discord" | "linkedin" | "apple"

export type UserProfile = {
    provider: Provider
    id: string
    name: string
}

export type ProviderInfo = {
    provider: Provider
    name: string
    logo: string
    logoType: "image" | "fab" | "fas"
    logoColor: string
    connect: boolean
}
export type Web3ProviderInfo = {
    name: string
    image: string
}