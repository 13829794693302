import { MDBBtn } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import Login from "../login/Login";
import { Web3ProviderInfo } from "../services";

export type InputWalletProps = {
}

export type InputWalletState = {
    showModal?: boolean
    provider?: Web3ProviderInfo
}

export default function InputWallet(props: InputWalletProps) {
    const [state, setState] = useState<InputWalletState>({});
    const toggle = () => {
        setState({ ...state, showModal: !state.showModal });
    }
    const onSelect = (provider: Web3ProviderInfo) => {
        setState((state) => ({ ...state, provider, showModal: false }))
    }
    const { provider } = state;
    const button = () => {
        if (provider) {
            return <MDBBtn rounded onClick={toggle} size='lg' color='muted' className={`d-flex flex-grow-1 justify-content-center align-items-center gap-2`}>
                <Image width={24} src={provider.image}/><span>{provider.name}</span>
            </MDBBtn>;
        }
        return <MDBBtn rounded onClick={toggle} size='lg' color='muted' className={`d-flex flex-grow-1 justify-content-center align-items-center`}>
            <span>{texts.connectSourceWallet}</span>
        </MDBBtn>;
    }
    return <>
        {button()}
        {state.showModal && <Modal contentClassName="no-card" show onHide={toggle}> <Login hideSocial onProviderSelect={onSelect} /></Modal>}
    </>
}