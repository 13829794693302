import { MDBBtn } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import InputNumber from '../ui/InputNumber';
import Register from './Register';
import RegisterPrice from './RegisterPrice';

export default function RegisterPeriod() {
    return <Register step={1}>
        <div className='my-4 d-flex flex-column gap-4'>
            <InputNumber required btnText='Years' labelText='Registration period' />
            <RegisterPrice/>
            <MDBBtn size='lg' color={'primary'} rounded>{texts.submitPeriod}</MDBBtn>
        </div>
    </Register>
}