import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export type InputSearchProps={
    label: string
    className?:string
}
export default function InputSearch({label, className}:InputSearchProps) {
  return (
    <div className={`d-flex align-items-center gap-2 ${className} m-0`}>
      <InputBase placeholder={label} className="flex-grow-1"/>
      <IconButton type="submit"><SearchIcon /></IconButton>
    </div>
  );
}