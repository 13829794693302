import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, ListGroup, Modal, Image, Form, Spinner } from "react-bootstrap";
import { texts } from "../constants/text";
import { swapService, Token, configService } from "../services";


type ModalTokenState = {
    original?: Token[],
    filtered?: Token[]
    loading: boolean
}

type ModalTokenProps = {
    show: boolean,
    title?: string,
    addMyToken?: boolean,
    hideSearch?: boolean,
    onSelect(asset?: Token): void,
    filter?(asset?: Token): boolean
}

export default function ModalTokens(props: ModalTokenProps) {
    const [state, setState] = useState<ModalTokenState>({ loading: true });
    useEffect(() => {
        const MY_TOKEN = configService.getMyToken();
        const exec = async () => {
            const original = await swapService.getListTokens();
            const tmp = { ...original };
            if (props.addMyToken) {
                if (tmp.tokens.filter(e => e.symbol === MY_TOKEN.symbol).length === 0) {
                    tmp.tokens.push({ ...MY_TOKEN, logoURI: MY_TOKEN.logoURI })
                }
            }
            const assets: Token[] = tmp.tokens.map(e => {
                return e;
            }).filter(e => {
                if (props.filter) {
                    return props.filter(e)
                }
                return true;
            });
            setState({ original: assets, filtered: assets, loading: false });
        }
        exec();
    }, [props])
    const search = (e: ChangeEvent) => {
        let searchVal = ((e.target as any).value as string).toLowerCase();
        const filtered = state.original?.filter(e => e.name?.toLowerCase().indexOf(searchVal) > -1 || e.symbol?.toLowerCase().indexOf(searchVal) > -1)
        setState({ ...state, filtered });
    }
    const title = props.title || texts.selectToken;
    //TODO dynamic balance
    const balance = 2000000;
    return <Modal centered show={props.show} contentClassName="p-0 rad-20" dialogClassName="max-w400" onHide={() => props.onSelect(undefined)}>
        <Modal.Header className="d-flex flex-column align-items-stretch">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                <ListGroup.Item as="li" className="d-flex flex-column justify-content-between text-center p-2">
                    <Form.Label>{title}</Form.Label>
                    {!props.hideSearch && <FormControl placeholder={texts.search} onChange={search} className="rad-16" />}
                </ListGroup.Item>
            </ListGroup>
        </Modal.Header>
        <Modal.Body className="p-0 max-h50v overflow-scroll">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                {state.filtered?.map((a, index) => {
                    return <ListGroup.Item key={index} action as="li" className="d-flex align-items-center justify-content-between p-3" onClick={(_e) => props.onSelect(a)}>
                        <Image src={a.logoURI} className="me-3" width={40} height={40} />
                        <p className="flex-grow-1 m-0"> <b>{a.symbol}</b><br /><small>{a.name}</small></p>
                        <small>{balance}</small>
                    </ListGroup.Item>;
                })}
                {state.loading && <ListGroup.Item action as="li" className="d-flex align-items-center justify-content-between p-3" >
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" className="me-1" />
                </ListGroup.Item>}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
}