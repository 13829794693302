import { BigNumberish } from "ethers";
import moment from "moment";
import { DateUtils } from "./dateUtils";

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export class StringUtils {
    static search(value: string, searchText: string) {
        const safeValue = value || "";
        const safeSearchText = searchText || "";
        return safeValue.toLowerCase().indexOf(safeSearchText.toLowerCase()) > -1;
    }
    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static formatDateTime(date: BigNumberish|Date) {
        if(date instanceof Date){
            return moment(date).format(DATETIME_FORMAT);
        }
        return moment(DateUtils.fromBigNumber(date)).format(DATETIME_FORMAT);
    }
}