import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBBtn, MDBNavbarLink } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { networks } from "../constants/constants";
import { texts } from "../constants/text";
import ModalNetworks from "../modals/ModalNetworks";
import { Network, web3Service } from "../services";

export type NetworkBadgeProps = {
}

export type NetworkBadgeState = {
    showModal?: boolean
    chainId?: number
}

export default function NetworkBadge(_: NetworkBadgeProps) {
    const [state, setState] = useState<NetworkBadgeState>({})
    //subscribe
    useEffect(() => {
        const subscription = web3Service.onNetworkChange.subscribe(network => {
            setState((state) => ({ ...state, chainId: network.chainId }));
        });
        return () => subscription.unsubscribe();
    }, []);
    //load
    const found = networks.filter(e => e.chainId === state.chainId)[0];
    const onToggle = () => {
        setState((state) => ({ ...state, showModal: !state.showModal }))
    }
    const onSelect = (network?: Network) => {
        if (network) {
            web3Service.setNetwork(network);
        }
        setState((state) => ({ ...state, showModal: false }))
    }
    const { showModal } = state;
    const wrapper = (child: JSX.Element) => {
        return <>
            <MDBBtn onClick={onToggle} rounded color="dark" className="d-none d-lg-flex align-items-center gap-2 h-100">
                {child}
            </MDBBtn>
            <MDBNavbarLink onClick={onToggle} href="#" className="px-3 d-flex d-lg-none gap-2 align-items-center">{child}</MDBNavbarLink>
            <ModalNetworks show={showModal!} onSelect={onSelect} />
        </>
    }
    if (!found) {
        return wrapper(<>
            <span className="text-nowrap">{texts.selectNetworkBadge}</span>
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
        </>)
    }
    const type = found?.main ? "" : " (Testnet)"
    return wrapper(<>
        <Image src={found!.logo} width={22} />
        <span className="text-nowrap">{found?.name}<small>{type}</small></span>
        <FontAwesomeIcon icon={faAngleRight} size="lg" />
    </>)
}