
export const routes={
    swap: "/swap",
    account: "/dashboard/accounts",
    deposit: "/dashboard/deposit",
    transfer: "/dashboard/transfer",
    history: "/dashboard/history",
    preferences: "/dashboard/preferences",
    nft: "/dashboard/nft",
    nftMint: "/dashboard/nft/mint",
    nftDeposit: "/dashboard/nft/deposit",
    nftImport: "/dashboard/nft/import",
    nftTransfer: "/dashboard/nft/transfer",
    login: "/login",
    login2fa: "/login/2fa",
    register2fa: "/register/2fa",
    registerPeriod: "/register/period",
    registerGas: "/register/gas",
    registerConfirm: "/register/confirm",
    documentation: "#",//TODO
    renew: "#"//TODO
}