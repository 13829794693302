import { CheckCircle } from '@mui/icons-material';
import { Divider, Step, StepLabel, Stepper } from '@mui/material';
import { MDBContainer } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import Panel from '../ui/Panel';

export type RegisterState = {
}

export type RegisterProps = {
    step: 1 | 2 | 3
    children: JSX.Element | JSX.Element[]
}

export default function Register({ step, children }: RegisterProps) {

    const sx = {
        '& .Mui-completed': {
            color: 'var(--mdb-primary) !important;'
        },
        '& .Mui-active': {
            color: 'var(--mdb-primary) !important;'
        },
    };
    const sxDivivder = {
        '&': {
            height: 'auto !important;'
        }
    }
    return <Panel title={texts.registerTitle} icon='credit-card' bodyClassName='flex-md-row gap-3'>
        <MDBContainer className='d-flex flex-column gap-2 my-2'>
            <p className='h3 text-bold text-center'>0.5 <small className='small'>BNB / {texts.year}</small></p>
            <div>
                <p className='d-flex gap-2 align-items-center lh-sm'><CheckCircle className='text-success' />{texts.featuresVault}</p>
                <p className='d-flex gap-2 align-items-center lh-sm'><CheckCircle className='text-success' />{texts.featuresSecures}</p>
                <p className='d-flex gap-2 align-items-center lh-sm'><CheckCircle className='text-success' />{texts.featuresPrivacy}</p>
                <p className='d-flex gap-2 align-items-center lh-sm'><CheckCircle className='text-success' />{texts.featuresEasy}</p>
            </div>
            <i className='text-center'>{texts.featuresConclude}</i>
        </MDBContainer>
        <Divider orientation='vertical' sx={sxDivivder} className="my-3" />
        <MDBContainer className='d-flex flex-column gap-2 my-2'>
            <p className='h4 text-bold text-center'>{texts.signupTitle}</p>
            <Stepper activeStep={step - 1}>
                <Step sx={sx} key={1}><StepLabel>{step === 1 ? texts.selectPeriod : ''}</StepLabel></Step>
                <Step sx={sx} key={2}><StepLabel>{step === 2 ? texts.buyGas : ''}</StepLabel></Step>
                <Step sx={sx} key={3}><StepLabel>{step === 3 ? texts.confirmation : ''}</StepLabel></Step>
            </Stepper>
            {children}
        </MDBContainer>
    </Panel>
}