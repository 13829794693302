export const texts={
    id:'ID',
    date: 'Date',
    action: 'Action',
    fromDate: 'From Date',
    toDate: 'To Date',
    noHistory: 'No History',
    appName: "Uniwal",
    logout: "Logout",
    select: 'Select',
    loading: "Loading...",
    trade: "Trade",
    transfer: "Transfer",
    deposit: "Deposit",
    nftdeposit: "NFT DEPOSIT",
    transferNFT: "TRANSFER NFT",
    documentation: "Documentation",
    dashboard: "Dashboard",
    fromCurrency:"FROM",
    amount: 'AMOUNT',
    toCurrency:"TO",
    connectWallet: "CONNECT WALLET",
    swap:"Swap",
    swapSubtitle: 'Trade tokens in an instant',
    price: `Price`,
    estimatedGas: `Estimated Gas`,
    coinSymbol: 'BNB',
    minimumReceived:'Minimum received',
    liquidityProviderFee:'Liquidity Provider Fee',
    priceImpact: 'Price impact',
    slippage: 'Slippage',
    search: "Search",
    selectToken: "Select a token",
    selectNetwork: "Select a network",
    selectProvider: "Select a provider",
    selectNetworkBadge: "SELECT NETWORK",
    gasFee1 : 'Validating a transaction on the blockchain requires a certain amount of gas, depending on the size and type of the transaction.',
    gasFee2: 'Gas measures the amount of work miners need to do in order to include transactions in a block.',
    gasFee3: 'So you may need to get some Coin to pay for gas',
    iUnderstand: 'I UNDERSTAND',
    swapSettings: 'Swap settings',
    gasPriceTitle: 'What gas fee means?',
    connectSourceWallet: 'CONNECT TO SOURCE WALLET',
    slippageLabel: 'Slippage:',
    transactionSpeed: 'Transaction speed:',
    transactionDeadline: 'Transaction deadline:',
    minutes: 'minutes',
    signIn: "SIGN IN",
    auth2FA: "2 FACTOR AUTHENTICATION",
    auth2FASubtitle: "Strengthen your vault's security by adding two-factor authentication!",
    scanQRCode: 'Scan QR Code',
    validateCode: 'Validate Code',
    scanQRCOdeLabel: 'Scan this QR-Code in your 2-FA app (Google Authenticator, Microsoft Authenticator...)',
    done: 'DONE',
    submit: 'SUBMIT',
    auth6Digit: 'Enter the 6 digit code generated by the application',
    back: 'BACK',
    signInSocial: 'USING SOCIAL NETWORKS',
    signInWeb3: 'OR USING YOUR WEB3 PROVIDER',
    actionsUpper: 'ACTIONS',
    depositUpper: 'DEPOSIT',
    tradeUpper: 'TRADE',
    transferUpper: 'TRANSFER',
    buy: 'BUY',
    accounts: 'Accounts',
    nft: 'NFT',
    history: 'History',
    preferences: 'Preferences',
    gasAccount: 'Gas Account',
    cryptoAccount: 'Crypto Accounts',
    searchByName: 'Search by name or address',
    featuresAccess: 'Access to your crypto and NFT using your social network accounts',
    featuresConnect: 'Connect to all your favorite crypto apps using uniwal',
    featuresPrivacy: 'Your privacy is guaranteed thanks to encryption algorithms - nobody can track your transactions!',
    featuresPersonnal: 'No personal data is stored off-chain',
    featuresEnjoy: 'Enjoy many features without the complexity of the web3',
    featuresConclude: 'And many more features and applications to come in the Uniwal community!',
    featuresVault: 'A digital vault to access all your crypto and NFT using your social network account',
    featuresSecures: 'A secure, decentralized, cross-chain protocol that does not store any personal data off-chain',
    featuresEasy: 'Enjoy your favorite crypto apps using your digital vault without the complexity of the web3',
    registerTitle: 'REGISTER',
    year: 'year',
    signupTitle: 'Sign up in just 3 steps',
    selectPeriod: 'Select Period',
    buyGas: 'Buy Gas',
    confirmation: 'Confirmation',
    registrationPrice: 'Cost for',
    totalPrice: 'Total Price',
    yourGasBalance: 'Your Balance',
    submitPeriod: 'SUBMIT PERIOD',
    submitRegister: 'SUBMIT REGISTRATION',
    registrationGas: 'To complete your digital vault creation you must pay gas fees (transaction fees on the blockchain).',
    registrationGasCB: 'Buy gas using a credit card through OnRamp',
    registrationGasDeposit: 'Or deposit BNB from a wallet you already own',
    contract: 'Contract',
    owner: 'Owner',
    delete: 'Delete',
    mintNft: 'MINT NFT',
    importNft: 'IMPORT NFT',
    searchNft: 'Search by id or address',
    emptyNft: "You dont have NFT yet, mint a new one!",
    uploadImage: 'Drag & Drop Image or Click to Upload',
    title: "Title",
    description: 'Description',
    remove: 'REMOVE',
    importNFT: 'IMPORT NFT',
    nftId: 'Token ID',
    searchNFT: 'SEARCH NFT',
    placeholderApple: 'Apple ID',
    placeholderGoogle: 'Google mail',
    placeholderDiscord: 'Discord handle',
    placeholderTwitter: 'Twitter handle',
    placeholderFb: 'Facebook handle',
    placeholderInsta: 'Instagram handle',
    placeholderTwitch: 'Twitch handle',
    placeholderLinkedin: 'Linkedin login',
    placeholderGithub: 'Github email',
    placeholderWeb3: 'Wallet Address 0x....',
    placeholderTelegram: 'Telegram phone number',
    security: 'Security',
    enable2FA: 'Enable 2FA Authentication',
    account: 'Account',
    registration: 'Registration',
    renew: 'Renew',
    payments: 'Payments',
    years: 'Years',
    validUntil: 'Valid Until'
}