import { ConfigService } from "./configService";
import { SwapService } from "./swapService";
import { VaultService } from "./vaultService";
import { Web3Service } from "./web3Service";

export * from "./configService";
export * from "./swapService";
export * from "./vaultService";
export * from "./web3Service";

export const configService = new ConfigService();
export const swapService = new SwapService(configService);
export const web3Service = new Web3Service(swapService);
export const vaultService = new VaultService(swapService);