import { texts } from "../constants/text";
import { ProviderInfo } from "../services";



export function placeholderFor(provider: ProviderInfo){
    switch(provider.provider){
        case "apple":
            return texts.placeholderApple;
        case "discord":
            return texts.placeholderDiscord;
        case "facebook":
            return texts.placeholderFb;
        case "github":
            return texts.placeholderGithub;
        case "google":
            return texts.placeholderGoogle;
        case "instagram":
            return texts.placeholderInsta;
        case "linkedin":
            return texts.placeholderLinkedin;
        case "telegram":
            return texts.placeholderTelegram;
        case "twitch":
            return texts.placeholderTwitch;
        case "twitter":
            return texts.placeholderTwitter;
        case "web3":
            return texts.placeholderWeb3;
        case "youtube":
            return texts.placeholderGoogle;
    }
}