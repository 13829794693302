import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBTabs, MDBTabsItem, MDBTabsLink } from "mdb-react-ui-kit";
import { routes } from "../constants/routes";
import { texts } from "../constants/text";

export type DashboardProps = {
    className?:string
    active: "accounts" | "nft" | "history" | "preferences"
    children: JSX.Element | JSX.Element[]
}

export default function Dashboard({children, active,className}:DashboardProps) {
    return <>
        <MDBCol size={12} className={className}>
            <MDBCard>
                <MDBCardHeader>
                    <MDBTabs className='card-header-tabs'>
                        <MDBTabsItem><MDBTabsLink href={routes.account} active={active==="accounts"}>{texts.accounts}</MDBTabsLink></MDBTabsItem>
                        <MDBTabsItem><MDBTabsLink href={routes.nft} active={active==="nft"}>{texts.nft}</MDBTabsLink></MDBTabsItem>
                        <MDBTabsItem><MDBTabsLink href={routes.history} active={active==="history"}>{texts.history}</MDBTabsLink></MDBTabsItem>
                        <MDBTabsItem><MDBTabsLink href={routes.preferences} active={active==="preferences"}>{texts.preferences}</MDBTabsLink></MDBTabsItem>
                    </MDBTabs>
                </MDBCardHeader>
                <MDBCardBody className='d-flex flex-column'>
                    {children}
                </MDBCardBody>
            </MDBCard>
        </MDBCol>
    </>
}