import { MDBBtn } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import InputNumber from '../ui/InputNumber';
import Panel from '../ui/Panel';

export type Login2FAProps = {
}

export type Login2FAState = {
}

export default function Login2FA(props: Login2FAProps) {
    return <Panel className='max-w480' bodyClassName='gap-3' title={texts.auth2FA} icon='shield-alt'>
        <p className='m-0'>{texts.auth6Digit}</p>
        <InputNumber max={999999} />
        <MDBBtn rounded color={'primary'}>{texts.submit}</MDBBtn>
    </Panel>
}