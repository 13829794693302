import { MDBIcon, MDBNavbarLink } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { texts } from "../constants/text";
import { web3Service } from "../services";

export type LogoutButtonProps = {
    onLoggedOut():void
}

export type LogoutButtonState = {
    loggedIn?: boolean
}

export default function LogoutButton(props: LogoutButtonProps) {
    const [state, setState] = useState<LogoutButtonState>({})
    //subscribe
    useEffect(() => {
        const subscription = web3Service.onUserProfileChange.subscribe(user => {
            setState((state) => ({ ...state, loggedIn: !!user?.id }));
        });
        return () => subscription.unsubscribe();
    }, []);
    const onClick = () => {
        web3Service.logout();
        props.onLoggedOut();
    }
    //load
    if(!state.loggedIn){
        return <></>
    }
    return <>
        <MDBNavbarLink onClick={onClick} href="#" className="d-none d-lg-flex justify-content-center align-items-center rounded-circle h-100 aspect-ratio-1 me-2">
            <MDBIcon fas icon='sign-out-alt' />
        </MDBNavbarLink>
        <MDBNavbarLink onClick={onClick} href="#" className="px-3 d-flex d-lg-none gap-2 align-items-center">
            <MDBIcon fas icon='sign-out-alt' className="px-1" />{texts.logout}
        </MDBNavbarLink>
    </>
}