import React, { useState } from 'react';
import { MDBBtn, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import { ProviderInfo } from '../services';
import { providers } from '../constants/constants';
import ModalProviders from '../modals/ModalProviders';
import { texts } from '../constants/text';
import * as socialUtils from "../utils/socialUtils";
export type InputUserProps = {
    labelText: string
}

export type InputUserState = {
    showModal?: boolean
    provider?: ProviderInfo
}

export default function InputUser({ labelText }: InputUserProps) {
    //TODO
    const p = providers[0];
    const [state, setState] = useState<InputUserState>({ provider: p });
    const onSelect = () => {
        setState({ ...state, showModal: false })
    }
    const onOpen = () => {
        setState({ ...state, showModal: true })
    }
    const { provider } = state;
    const placeholder = socialUtils.placeholderFor(provider!);
    return <>
        <div>
            <div className='position-relative'>
                <MDBInput wrapperClass='custom-input' label={labelText} type='text' size='lg' color='light' placeholder={placeholder}/>
                <div className='position-absolute top-50 end-0 index-10 me-3 translate-middle-y d-flex align-items-center gap-2'>
                    <MDBBtn className='d-flex align-items-center gap-2 px-3' color='light' rounded onClick={onOpen}>
                        {provider && <MDBIcon fab={provider.logoType==="fab"}  fas={provider.logoType==="fas"} icon={provider.logo} style={{ background: provider.logoColor }} />}
                        <span>{provider?.name || texts.select}</span>
                        <MDBIcon fas icon='angle-down' />
                    </MDBBtn>
                </div>
            </div>
        </div>
        <ModalProviders onSelect={onSelect} show={state.showModal!} />
    </>
}