import { MDBBtn, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalHeader, MDBModalTitle } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { Close } from '@mui/icons-material';

export type ModalInfoProps = {
    show:boolean
    hideButton?:boolean
    showClose?:boolean
    title: string | JSX.Element
    body: string | JSX.Element
    onClose():void
}

export default function ModalInfo({show, title, body,hideButton, showClose,onClose}:ModalInfoProps){
    const setShow:React.SetStateAction<boolean> = (show:boolean) => {
        if(!show){
            onClose()
        }
        return show;
    }
    return <MDBModal show={show} appendToBody setShow={setShow}>
        <MDBModalDialog>
            <MDBModalContent>
                <MDBModalHeader className="bg-primary text-white">
                    <MDBModalTitle className="d-flex justify-content-between align-items-center w-100">{title}{showClose && <Close onClick={onClose} className="pointer"/>}</MDBModalTitle>
                </MDBModalHeader>
                <MDBModalBody className="d-flex gap-3 flex-column align-items-center py-4 px-5">
                    {body}
                    {!hideButton && <MDBBtn size="lg" color="primary" className="no-radius" onClick={onClose}>{texts.iUnderstand}</MDBBtn>}
                </MDBModalBody>
            </MDBModalContent>
        </MDBModalDialog>
    </MDBModal>
}