import { DateUtils } from "../utils/dateUtils";
import { SwapService } from "./swapService";
import { UserProfile } from "./web3Service";

export class VaultService {
    constructor(private swapService: SwapService) { }

    async getGasBalance(): Promise<Balance> {
        const all = await this.swapService.getListTokens();
        const bnb = all.tokens.find(e => e.symbol === "BNB")
        return {
            balance: "10",
            logo: bnb!.logoURI,
            name: bnb!.name,
            symbol: bnb!.symbol
        }
    }

    async getBalances(): Promise<Balance[]> {
        const all = await this.swapService.getListTokens();
        return all.tokens.filter(e => ["BNB", "USDC", "USDT", "BUSD"].indexOf(e.symbol) > -1).map(e => {
            return {
                balance: "10",
                logo: e!.logoURI,
                name: e!.name,
                symbol: e!.symbol
            }
        })
    }

    async getNFTS(): Promise<NFT[]> {
        return [1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(e => {
            const img = ["https://mdbootstrap.com/img/new/standard/city/041.webp",
                "https://mdbootstrap.com/img/new/standard/nature/111.webp",
                'https://mdbootstrap.com/img/new/standard/city/044.webp'
            ]
            return {
                url: img[Math.floor(Math.random() * img.length)],
                id: "3400000",
                contract: "0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949",
                owner: '0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949'
            }
        })
    }

    getNFTTest(): NFT {
        return {
            url: "https://mdbootstrap.com/img/new/standard/city/041.webp",
            id: "3400000",
            contract: "0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949",
            owner: '0x306b1ea3ecdf94ab739f1910bbda052ed4a9f949'
        }
    }

    async getHistory(): Promise<HistoryRow[]> {
        //TODO
        return [
            { id: 1, date: new Date(), action: 'Transfer NFT #11111 to @john (facebook)' } as HistoryRow,
            { id: 2, date: new Date(), action: 'Transfer NFT #22222 to @john (facebook)' } as HistoryRow,
            { id: 3, date: new Date(), action: 'Transfer 1 BNB to @john (twitter)' } as HistoryRow,
            { id: 4, date: new Date(), action: 'Transfer 1USDC to wallet 0x111112' } as HistoryRow,
            { id: 5, date: new Date(), action: 'Transfer NFT #33333 to 0x111111' } as HistoryRow,
            { id: 6, date: new Date(), action: 'Deposit 1BNB' } as HistoryRow,
            { id: 7, date: new Date(), action: 'Withdraw 1BNB' } as HistoryRow,
        ]
    }

    async getPreferences():Promise<PreferencesModel> {
        return {
            twoFactor:{
                enabled: true,
            },
            accounts:[
                {id: "test", name: "test", provider:"facebook"},
                {id: "test", name: "test", provider:"apple"}
            ],
            registration:{
                validUntil: DateUtils.addDaysD(DateUtils.now(), 1000),
                payments:[
                    {payOn: DateUtils.addDaysD(DateUtils.now(), -10), amountOfYear: 2, amountPaid: 1, symbol:"BNB"}
                ]
            }
        }
    }
}

export type RegistrationPayment={
    payOn:Date
    amountOfYear: number
    amountPaid: number
    symbol: string
}

export type Registration = {
    validUntil: Date
    payments: RegistrationPayment[]
}

export type PreferencesModel = {
    twoFactor: {
        enabled: boolean
    },
    accounts: UserProfile[]
    registration: Registration
}

export type HistoryRow = {
    date: Date,
    action: string
}

export type Balance = {
    name: string;
    logo: string;
    symbol: string;
    balance: string;
}

export type NFT = {
    id: string;
    owner: string;
    contract: string
    url: string;
}