import React, { } from "react";
import "./file-upload.min.css";
import FileUploadWidget, {Config} from "./file-upload";
export type OptConfig = Partial<Config>;

export class FileUpload extends React.Component<OptConfig> {
    private el?:HTMLInputElement;
    private widget?:FileUploadWidget;
    componentDidMount?(): void {
        this.widget = new FileUploadWidget(this.el!, this.props);
    }

    componentWillUnmount?(): void {
        this.widget?.reset();
        this.widget?.dispose();
        this.widget = undefined;
    }

    render() {
        return (
            <div className="file-upload-wrapper">
                <input type="file" className="file-upload-input" ref={el => this.el = el!}/>
            </div>
        )
    }
}