import { MDBContainer } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import GasPrice from '../ui/GasPrice';
import InputText from '../ui/InputText';
import InputSubmit from '../ui/InputSubmit';
import InputWallet from '../ui/InputWallet';
import Panel from '../ui/Panel';

export type NFTDepositState = {
}

export default function NFTDeposit() {
    const onBack = () => { }
    return <>
        <Panel onBack={onBack} title={texts.nftdeposit} icon='money-bill-transfer' className='max-w480 gap-1' bodyClassName='gap-2'>
            <MDBContainer className='d-flex flex-column gap-3 my-2 px-0'>
                <InputWallet />
                <InputText labelText={texts.contract} />
                <InputText labelText={texts.nftId} />
                <GasPrice />
                <InputSubmit labelText={texts.nftdeposit} />
            </MDBContainer>
        </Panel>
    </>
}