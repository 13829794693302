import { MDBBtn } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { InfoOutlined } from '@mui/icons-material';
import ModalInfo from "../modals/ModalInfo";
import { useState } from "react";

export type GasPriceProps = {
    vertical?: boolean
}

export type GasPriceState = {
    showModal?: boolean
}

export default function GasPrice({ vertical }: GasPriceProps) {
    const [state, setState] = useState<GasPriceState>({});
    const clazz = vertical ? "flex-column" : ""
    const onToggle = () => {
        setState({...state, showModal: !state.showModal});
    }
    const title = <div className="d-flex gap-2 align-items-center">
        <InfoOutlined className="me-2" /><span>{texts.gasPriceTitle}</span>
    </div>
    const body = <div className="d-flex flex-column gap-2">
        <p>{texts.gasFee1}<br/>{texts.gasFee2}</p><b>{texts.gasFee3}</b>
    </div>
    return <>
        <MDBBtn rounded onClick={onToggle} size='lg' color='muted' className={`d-flex ${clazz} flex-grow-1 justify-content-between align-items-center`}>
            <span><InfoOutlined className="me-2" />{texts.estimatedGas}</span>
            <b>0.002 {texts.coinSymbol}</b>
        </MDBBtn>
        {state.showModal && <ModalInfo show={state.showModal!} onClose={onToggle} title={title} body={body}/>}
    </>
}