import { MDBBtnGroup, MDBCol, MDBRadio, MDBRange } from "mdb-react-ui-kit";
import { texts } from "../constants/text";
import { Settings } from '@mui/icons-material';
import ModalInfo from "../modals/ModalInfo";

export type SwapSettingsProps = {
    slippage?: number
    transactionSpeed?: number
    deadline?: number
    show: boolean
    onClose(): void
}

export default function SwapSettings({ onClose, show, slippage, deadline, transactionSpeed }: SwapSettingsProps) {
    const slippageValue = slippage || 1;
    const speedValue = transactionSpeed || 5;
    const deadlineValue = deadline || 20;
    const form = () => {
        return <MDBCol size={12} className='d-flex flex-column gap-3'>
            <MDBRange value={slippageValue} label={`${texts.slippageLabel} ${slippageValue}%`} min={0.1} max={49} step="0.1" />
            <div>
                <label className="form-label">{texts.transactionSpeed}</label>
                <MDBBtnGroup className="d-flex">
                    <MDBRadio btn btnColor='primary' disableWrapper label='Normal (5)' value={5} defaultChecked={speedValue === 5} />
                    <MDBRadio btn btnColor='primary' disableWrapper label='Fast (6)' value={6} defaultChecked={speedValue === 6} />
                    <MDBRadio btn btnColor='primary' disableWrapper label='Instant (7)' value={7} defaultChecked={speedValue === 7} />
                </MDBBtnGroup>
            </div>
            <MDBRange value={deadlineValue} label={`${texts.transactionDeadline} ${deadlineValue} ${texts.minutes}`} min={1} max={90} step="1" />
        </MDBCol>
    }
    const modal = () => {
        return <ModalInfo onClose={onClose} show={show} hideButton showClose
            title={<div className="d-flex gap-2 align-items-center">
                <Settings /><span>{texts.swapSettings}</span>
            </div>}
            body={form()}
        ></ModalInfo>;
    }
    return show ? modal() : <></>;
}