import { MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';

export type RegisterPriceProps = {
    gasBalance?: boolean
}

export default function RegisterPrice({gasBalance }: RegisterPriceProps) {
    return <>
        <MDBListGroup>
            <MDBListGroupItem disabled className="text-small d-flex justify-content-between small"><span>{texts.registrationPrice} 1 year</span><b>0.5BNB</b></MDBListGroupItem>
            <MDBListGroupItem disabled className="text-small d-flex justify-content-between small"><span>{texts.estimatedGas}</span><b>0.1BNB</b></MDBListGroupItem>
            <MDBListGroupItem disabled className="text-small d-flex justify-content-between small"><span>{texts.totalPrice}</span><b>0.6BNB</b></MDBListGroupItem>
            {gasBalance && <MDBListGroupItem disabled className="text-small d-flex justify-content-between small"><span>{texts.yourGasBalance}</span><b>0.9BNB</b></MDBListGroupItem>}
        </MDBListGroup>
    </>
}