import { MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { texts } from "../constants/text";

export type SwapPriceDetailsProps={
}

export default function SwapPriceDetails(){
    return <MDBListGroup>
    <MDBListGroupItem disabled className="text-small d-flex justify-content-between small"><span>{texts.minimumReceived}</span><b>0.567BNB</b></MDBListGroupItem>
    <MDBListGroupItem disabled className="d-flex justify-content-between small"><span>{texts.priceImpact}</span><b>-0.02%</b></MDBListGroupItem>
    <MDBListGroupItem disabled className="d-flex justify-content-between small"><span>{texts.slippage}</span><b>0.5%</b></MDBListGroupItem>
  </MDBListGroup>
}