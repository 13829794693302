import { MDBContainer } from "mdb-react-ui-kit";
import { GridRowsProp, GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { useEffect, useState } from "react";
import { texts } from "../constants/text";
import { HistoryRow, vaultService } from "../services";
import Loader from "../ui/Loader";
import Dashboard from "./dashboard";
import TableMUI from "../ui/TableMUI";
import { StringUtils } from "../utils/stringUtils";
import InputDate from "../ui/InputDate";
import InputSearch from "../ui/InputSearch";

export type HistoryState = {
    loading: boolean
    history?: HistoryRow[],
}

export default function History() {
    const [state, setState] = useState<HistoryState>({ loading: true });
    //subscribe
    const reload = async () => {
        const history = await vaultService.getHistory();
        setState((state) => ({ ...state, history, loading: false }));
    }
    useEffect(() => {
        reload();
    }, []);
    const { loading, history } = state;
    const columns: GridColumns = [
        { field: 'id', headerName: texts.id },
        {
            field: 'date', headerName: texts.date, minWidth: 140,
            valueGetter: (params: GridValueGetterParams<string, HistoryRow>) => {
                return StringUtils.formatDateTime(params.row.date);
            }
        },
        { field: 'action', headerName: texts.action, flex: 1 }
    ]
    const rows: GridRowsProp = [...(history || [])];
    if (loading) {
        return <Dashboard active="accounts" className="max-w800">
            <Loader />
        </Dashboard>
    }
    return <Dashboard active="history" className="max-w800">
        <MDBContainer className='d-flex gap-4 flex-column my-4' fluid>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex gap-2">
                    <InputDate type="datetime" labelText={texts.fromDate} />
                    <InputDate type="datetime" labelText={texts.toDate} />
                </div>
                <InputSearch label={texts.search} />
            </div>
            <TableMUI
                autoHeight={false}
                rows={rows}
                columns={columns}
                localeText={{ noRowsLabel: texts.noHistory }}
                disableColumnMenu={true}
                checkboxSelection={false}
                disableSelectionOnClick
            />
        </MDBContainer>
    </Dashboard>
}