import React, { } from 'react';
import { MDBBtn, MDBInput } from "mdb-react-ui-kit";

export type InputNumberProps = {
    labelText?: string
    btnText?:string
    placeholder?:string
    required?:boolean
    max?:number
}

export type InputNumberState = {
}

export default function InputNumber({ max, required,labelText, btnText, placeholder }: InputNumberProps) {
    return <>
        <div className='position-relative'>
            <MDBInput max={max} required={required} wrapperClass='custom-input' label={labelText} type='number' size='lg' color='light' placeholder={placeholder}/>
            {btnText && <div className='position-absolute top-50 end-0 index-10 me-3 translate-middle-y d-flex align-items-center gap-2'>
                <MDBBtn  color='light' rounded>
                    <span>{btnText}</span>
                </MDBBtn>
            </div>}
        </div>
    </>
}