import { Network, ProviderInfo, Web3ProviderInfo } from "../services";
import { assets } from "./assets";

//TODO correctchain ID
export const networks: Network[] = [
    { name: 'Arbitrum', logo: assets.logoArbitrum, main: true, chainId: 0 },
    { name: 'BSC', logo: assets.logoBsc, main: true, chainId: 0 },
    { name: 'BSC', logo: assets.logoBsc, main: false, chainId: 1 },
    { name: 'Chronos', logo: assets.logoCronos, main: false, chainId: 0 },
    { name: 'Ethereum', logo: assets.logoEth, main: true, chainId: 0 },
    { name: 'Fantom', logo: assets.logoFantom, main: true, chainId: 0 },
    { name: 'KuCoin', logo: assets.logoKuCoin, main: true, chainId: 0 },
    { name: 'Matic Mumbai', logo: assets.logoPolygon, main: false, chainId: 0 },
    { name: 'Matic Polygon', logo: assets.logoPolygon, main: true, chainId: 0 },
    { name: 'Optimism', logo: assets.logoOptimism, main: true, chainId: 0 },
    { name: 'Ropstein', logo: assets.logoEth, main: false, chainId: 0 }
]

export const providers: ProviderInfo[] = [
    { provider: "apple", name: "Apple", logo: "apple", logoType: "fab", logoColor: '#000000', connect: true } as ProviderInfo,
    { provider: "discord", name: "Discord", logo: "discord", logoType: "fab", logoColor: '#7289DA', connect: false } as ProviderInfo,
    { provider: "facebook", name: "Facebook", logo: "facebook", logoType: "fab", logoColor: '#3b5998', connect: true } as ProviderInfo,
    { provider: "github", name: "Github", logo: "github", logoType: "fab", logoColor: '#333333', connect: true } as ProviderInfo,
    { provider: "google", name: "Google", logo: "google", logoType: "fab", logoColor: '#EA4335', connect: true } as ProviderInfo,
    { provider: "instagram", name: "Instagram", logo: "instagram", logoType: "fab", logoColor: '#ac2bac', connect: false } as ProviderInfo,
    { provider: "linkedin", name: "Linked-in", logo: "linkedin", logoType: "fab", logoColor: '#0082ca', connect: true } as ProviderInfo,
    { provider: "telegram", name: "Telegram", logo: "telegram", logoType: "fab", logoColor: '#3b5998', connect: false } as ProviderInfo,
    { provider: "twitch", name: "Twitch", logo: "twitch", logoType: "fab", logoColor: '#3b5998', connect: false } as ProviderInfo,
    { provider: "twitter", name: "Twitter", logo: "twitter", logoType: "fab", logoColor: '#55acee', connect: true } as ProviderInfo,
    { provider: "web3", name: "Address", logo: "wallet", logoType: "fas", logoColor: 'var(--mdb-tertiary)', connect: false } as ProviderInfo,
    { provider: "youtube", name: "Youtube", logo: "youtube", logoType: "fab", logoColor: '#ed302f', connect: false } as ProviderInfo,
].sort((a, b) => {
    return a.name.localeCompare(b.name)
}) as ProviderInfo[];


export const web3Providers: Web3ProviderInfo[] = [
    { name: "Metamask", image: assets.metamask } as Web3ProviderInfo,
    { name: "Wallet Connect", image: assets.walletConnect } as Web3ProviderInfo,
    { name: "Web3 Browser Extension", image: assets.web3 } as Web3ProviderInfo,
    { name: "Ledger", image: assets.ledger } as Web3ProviderInfo,
    //{ name: "Authereum", image: assets.authereum } as Web3ProviderInfo,
    //{ name: "Bitski", image: assets.bitski } as Web3ProviderInfo,
    { name: "Formatic", image: assets.formatic } as Web3ProviderInfo
];
export const web3ProvidersSorted: Web3ProviderInfo[] = web3Providers.sort((a, b) => {
    return a.name.localeCompare(b.name)
}) as Web3ProviderInfo[];