import React, { } from 'react';
import { MDBBtn, MDBInput, MDBTextArea } from "mdb-react-ui-kit";

export type InputTextProps = {
    labelText?: string
    btnText?:string
    placeholder?:string
    required?:boolean
    rows?:number
}

export type InputTextState = {
}

export default function InputText({ rows, required,labelText, btnText, placeholder }: InputTextProps) {
    return <>
        <div className='position-relative'>
            {!rows && <MDBInput required={required} wrapperClass='custom-input' label={labelText} type='text' size='lg' color='light' placeholder={placeholder}/>}
            {rows && <MDBTextArea required={required} wrapperClass='custom-area' label={labelText} rows={rows} size='lg' color='light' placeholder={placeholder}/>}
            {btnText && <div className='position-absolute top-50 end-0 index-10 me-3 translate-middle-y d-flex align-items-center gap-2'>
                <MDBBtn  color='light' rounded>
                    <span>{btnText}</span>
                </MDBBtn>
            </div>}
        </div>
    </>
}