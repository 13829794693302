import { assets } from "../constants/assets";
import { Themes } from "../constants/theme";
import { Token } from "./swapService";



export class ConfigService{
    getCurrentTheme(){
        return Themes.LIGHT;
    }
    getCurrentLocale(){
        //TODO
        return 'en';
    }
    getCurrentChainId(){
        //TODO
        return 56;
    }
    getMyToken(){
        //TODO
        return {
            symbol: "TEST",
            name: "Test",
            address: "0x88888888888888888888",
            decimals: 18,
            logoURI: assets.bnbLogo
        } as Token
    }
}