import { MDBIcon } from "mdb-react-ui-kit";
import { ProviderInfo } from "../services";

type Size = '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl';

export default function ProviderIcon({provider:a, size, minWidth}:{
    provider:ProviderInfo, 
    size?:Size,
    minWidth?:number
}){
    return <MDBIcon className="text-center" style={{ color: a.logoColor, minWidth:minWidth! }} fab={a.logoType==="fab"} fas={a.logoType==="fas"} icon={a.logo} size={size} />;
}