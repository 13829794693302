import {Image} from 'react-bootstrap';
import { texts } from '../constants/text';
import { NFT, vaultService } from '../services';
import GasPrice from '../ui/GasPrice';
import InputSubmit from '../ui/InputSubmit';
import InputUser from '../ui/InputUser';
import Panel from '../ui/Panel';

export type NFTTransferState = {
}

export default function NFTTransfer() {
    const onBack = () =>{}
    //TODO
    const nft:NFT = vaultService.getNFTTest();
    return <>
        <Panel onBack={onBack} title={texts.transferNFT} icon='money-bill-transfer' className='max-w480 gap-1' bodyClassName='gap-3'>
                <div className='d-flex flex-column gap-1 mb-2'>
                    <b>#{nft.id}</b>
                    <Image src={nft.url} className="rounded w-100" />
                </div>
                <InputUser labelText={texts.toCurrency} />
                <GasPrice />
                <InputSubmit labelText={texts.transferNFT} />
        </Panel>
    </>
}