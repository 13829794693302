export const breakpointMap: { [key: string]: number } = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1080,
  xxl: 1200,
};

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark'
}

export const colors = {
  textDisabled: "#BDC2C4"
}