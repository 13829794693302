import React, { useState } from 'react';
import { MDBBtn, MDBIcon, MDBInput } from "mdb-react-ui-kit";
import { Image } from 'react-bootstrap';
import { assets } from '../constants/assets';
import ModalTokens from '../modals/ModalTokens';

export type InputCurrencyProps = {
    labelText: string
}

export type InputCurrencyState = {
    showModal?:boolean
}

export default function InputCurrency({ labelText }: InputCurrencyProps) {
    const [state, setState] = useState<InputCurrencyState>({});
    const onSelect = () => {
        setState({ ...state, showModal: false })
    }
    const onOpen = () => {
        setState({ ...state, showModal: true })
    }
    return <>
        <div>
        <div className='position-relative'>
            <MDBInput wrapperClass='custom-input' label={labelText} type='number' size='lg' color='light' placeholder='Balance: 50BNB'/>
            <div className='position-absolute top-50 end-0 index-10 me-3 translate-middle-y d-flex align-items-center gap-2'>
                <MDBBtn className='px-3' color={'tertiary' as any} outline rounded>MAX</MDBBtn>
                <MDBBtn className='d-flex align-items-center gap-2 px-3' color='light' rounded onClick={onOpen}>
                    <Image src={assets.bnbLogo} width={20} /><span>BNB</span>
                    <MDBIcon fas icon='angle-down' />
                </MDBBtn>
            </div>
        </div>
    </div>
    <ModalTokens onSelect={onSelect} show={state.showModal!} />
    </>
}