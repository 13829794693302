import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, ListGroup, Modal, Image, Form, Spinner } from "react-bootstrap";
import { networks } from "../constants/constants";
import { texts } from "../constants/text";
import { Network } from "../services";

type ModalNetworksState = {
    original?: Network[],
    filtered?: Network[]
    loading: boolean
}

type ModalNetworksProps = {
    show: boolean,
    title?: string,
    hideSearch?: boolean,
    onSelect(asset?: Network): void,
    filter?(asset?: Network): boolean
}

export default function ModalNetworks(props: ModalNetworksProps) {
    const [state, setState] = useState<ModalNetworksState>({ loading: true });
    useEffect(() => {
        const exec = async () => {
            const original = networks.slice();
            const tmp = [...original ];
            const assets: Network[] = tmp.filter(e => {
                if (props.filter) {
                    return props.filter(e)
                }
                return true;
            });
            setState({ original: assets, filtered: assets, loading: false });
        }
        exec();
    }, [props])
    const search = (e: ChangeEvent) => {
        let searchVal = ((e.target as any).value as string).toLowerCase();
        const filtered = state.original?.filter(e => e.name?.toLowerCase().indexOf(searchVal) > -1)
        setState({ ...state, filtered });
    }
    const title = props.title || texts.selectNetwork;
    return <Modal centered show={props.show} contentClassName="p-0 rad-20" dialogClassName="max-w400" onHide={() => props.onSelect(undefined)}>
        <Modal.Header className="d-flex flex-column align-items-stretch">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                <ListGroup.Item as="li" className="d-flex flex-column justify-content-between text-center p-2">
                    <Form.Label>{title}</Form.Label>
                    {!props.hideSearch && <FormControl placeholder={texts.search} onChange={search} className="rad-16" />}
                </ListGroup.Item>
            </ListGroup>
        </Modal.Header>
        <Modal.Body className="p-0 max-h50v overflow-scroll">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                {state.filtered?.map((a, index) => {
                    return <ListGroup.Item key={index} action as="li" className="d-flex align-items-center justify-content-between p-3" onClick={(_e) => props.onSelect(a)}>
                        <Image src={a.logo} className="me-3" width={40} height={40} />
                        <p className="flex-grow-1 m-0"><b>{a.name}</b></p>
                        <small>{a.main? "Mainnet":"Testnet"}</small>
                    </ListGroup.Item>;
                })}
                {state.loading && <ListGroup.Item action as="li" className="d-flex align-items-center justify-content-between p-3" >
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" className="me-1" />
                </ListGroup.Item>}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
}