import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import { texts } from '../constants/text';
import { assets } from '../constants/assets';
import { MDBNavbar, MDBContainer, MDBNavbarBrand, MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink } from 'mdb-react-ui-kit';
import NetworkBadge from './NetworkBadge';
import UserBadge from './UserBadge';
import LogoutButton from './LogoutButton';
import { routes } from '../constants/routes';

type NavBarState = {
    show: boolean,
    date?: Date
}

export function NavBar() {
    const [state, setState] = useState<NavBarState>({show:false});
    const toggleNav = () => {
        setState((state) => ({...state,show:!state.show}));
    }
    const onLoggedChange = () => {
        setState((state) => ({...state,date: new Date()}));
    }
    return <MDBNavbar bgColor='dark' dark expand='lg'>
        <MDBContainer breakpoint='lg' fluid>
            <MDBNavbarBrand><Image src={assets.logo} width={50} /></MDBNavbarBrand>
            <MDBNavbarBrand>{texts.appName}</MDBNavbarBrand>
            <MDBNavbarToggler onClick={toggleNav}><MDBIcon icon='bars' fas /></MDBNavbarToggler>
            <MDBCollapse navbar show={state.show} key={state.date?.toString()}>
                <MDBNavbarNav className='gap-2'>
                    <MDBNavbarItem><MDBNavbarLink href={routes.account} className="px-3">{texts.dashboard}</MDBNavbarLink></MDBNavbarItem>
                    <MDBNavbarItem><MDBNavbarLink href={routes.swap} className="px-3">{texts.trade}</MDBNavbarLink></MDBNavbarItem>
                    <MDBNavbarItem><MDBNavbarLink href={routes.documentation} className="px-3">{texts.documentation}</MDBNavbarLink></MDBNavbarItem>
                </MDBNavbarNav>
                <MDBNavbarNav className='gap-2' right fullWidth={false}>
                    <MDBNavbarItem><NetworkBadge /></MDBNavbarItem>
                    <MDBNavbarItem><UserBadge onLoggedIn={onLoggedChange}/></MDBNavbarItem>
                    <MDBNavbarItem><LogoutButton onLoggedOut={onLoggedChange} /></MDBNavbarItem>
                </MDBNavbarNav>
            </MDBCollapse>
        </MDBContainer>
    </MDBNavbar >
}