import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle, MDBListGroupItem } from "mdb-react-ui-kit";
import { Image } from "react-bootstrap"
import { texts } from "../constants/text";
import { Balance } from "../services";

export type AccountRowProps = {
    balance?: Balance
}

export default function AccountRow({ balance: e }: AccountRowProps) {
    return <MDBListGroupItem tag='div' action className="d-flex align-items-center gap-3">
        <Image src={e?.logo!} height={34} className="my-2" />
        <div className='d-flex flex-column flex-grow-1 min-w0'>
            <h6 className='m-0 d-flex align-items-center gap-2'>
                <span className="h4 text-bold m-0 lh-1 text-truncate">{e?.balance}</span>
                {e?.symbol}
            </h6>
            <small className="text-muted lh-1 fw-light">{e?.name}</small>
        </div>
        <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.depositUpper}</MDBBtn>
        <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.tradeUpper}</MDBBtn>
        <MDBBtn className="d-none d-md-block text-nowrap" size="sm" color={"warning"}>{texts.transferUpper}</MDBBtn>
        <MDBDropdown className="d-md-none text-nowrap">
            <MDBDropdownToggle color="warning">{texts.actionsUpper}</MDBDropdownToggle>
            <MDBDropdownMenu>
                <MDBDropdownItem><MDBDropdownLink href="#">{texts.depositUpper}</MDBDropdownLink></MDBDropdownItem>
                <MDBDropdownItem><MDBDropdownLink href="#">{texts.tradeUpper}</MDBDropdownLink></MDBDropdownItem>
                <MDBDropdownItem><MDBDropdownLink href="#">{texts.transferUpper}</MDBDropdownLink></MDBDropdownItem>
            </MDBDropdownMenu>
        </MDBDropdown>
    </MDBListGroupItem>
}