import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MDBBtn, MDBNavbarLink } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { providers } from "../constants/constants";
import { texts } from "../constants/text";
import ModalProviders from "../modals/ModalProviders";
import { Provider, web3Service } from "../services";
import ProviderIcon from "./ProviderIcon";

export type UserBadgeProps = {
    onLoggedIn():void
}

export type UserBadgeState = {
    showModal?: boolean
    name?: string
    provider?: Provider
}

export default function UserBadge(props: UserBadgeProps) {
    const [state, setState] = useState<UserBadgeState>({})
    //subscribe
    useEffect(() => {
        const subscription = web3Service.onUserProfileChange.subscribe(profile => {
            setState((state) => ({ ...state, name: profile?.name, provider: profile?.provider }));
        });
        return () => subscription.unsubscribe();
    }, []);
    //load
    const found = providers.filter(e => state.provider && e.provider === state.provider)[0];
    const onToggle = () => {
        setState((state) => ({ ...state, showModal: !state.showModal }))
    }
    const onSelect = (provider?: Provider) => {
        if (provider) {
            web3Service.setProvider(provider)
            props.onLoggedIn();
        }
        setState((state) => ({ ...state, showModal: false }))
    }
    const { showModal } = state;
    const wrapper = (child: JSX.Element) => {
        return <>
            <MDBBtn onClick={onToggle} rounded color="dark" className="d-none d-lg-flex align-items-center gap-2 h-100">
                {child}
            </MDBBtn>
            <MDBNavbarLink onClick={onToggle} href="#" className="px-3 d-flex d-lg-none gap-2 align-items-center">{child}</MDBNavbarLink>
            <ModalProviders show={showModal!} onSelect={onSelect} />
        </>
    }
    if (!found) {
        return wrapper(<>
            <span className="text-nowrap">{texts.connectWallet}</span>
            <FontAwesomeIcon icon={faAngleRight} size="lg" />
        </>)
    }
    return wrapper(<>
        <ProviderIcon provider={found!} size="lg" />
        <span className="text-nowrap">{state.name}</span>
        <FontAwesomeIcon icon={faAngleRight} size="lg" />
    </>)
}