import { MDBContainer } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import GasPrice from '../ui/GasPrice';
import InputCurrency from '../ui/InputCurrency';
import InputSubmit from '../ui/InputSubmit';
import InputWallet from '../ui/InputWallet';
import Panel from '../ui/Panel';

export type DepositState = {
}
//TODO transfer crypt / list NFT / transfer NFT / history transaction / preferences (startdate / end date / linked networks / 2FA)

export default function Deposit() {
    const onBack = () =>{}
    return <>
        <Panel onBack={onBack} title={texts.deposit} icon='money-bill-transfer' className='max-w480 gap-1' bodyClassName='gap-2'>
            <MDBContainer className='d-flex flex-column gap-3 my-2 px-0'>
                <InputWallet />
                <InputCurrency labelText={texts.amount} />
                <GasPrice />
                <InputSubmit labelText={texts.deposit} />
            </MDBContainer>
        </Panel>
    </>
}