import { MDBContainer } from 'mdb-react-ui-kit';
import { texts } from '../constants/text';
import GasPrice from '../ui/GasPrice';
import InputCurrency from '../ui/InputCurrency';
import InputSubmit from '../ui/InputSubmit';
import InputUser from '../ui/InputUser';
import Panel from '../ui/Panel';

export type TransferState = {
}

export default function Transfer() {
    const onBack = () =>{}
    return <>
        <Panel onBack={onBack} title={texts.transfer} icon='money-bill-transfer' className='max-w480 gap-1' bodyClassName='gap-2'>
            <MDBContainer className='d-flex flex-column gap-3 my-4 px-0'>
                <InputCurrency labelText={texts.amount} />
                <InputUser labelText={texts.toCurrency} />
                <GasPrice />
                <InputSubmit labelText={texts.connectWallet} />
            </MDBContainer>
        </Panel>
    </>
}