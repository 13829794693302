import { Divider } from "@mui/material";
import { MDBContainer, MDBIcon, MDBListGroup, MDBListGroupItem } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { providers } from "../constants/constants";
import { routes } from "../constants/routes";
import { texts } from "../constants/text";
import { PreferencesModel, Provider, vaultService } from "../services";
import InputSwitch from "../ui/InputSwitch";
import Loader from "../ui/Loader";
import { StringUtils } from "../utils/stringUtils";
import Dashboard from "./dashboard";

export type PreferencesState = {
    loading: boolean
    preferences?: PreferencesModel,
}

export default function Preferences() {
    const [state, setState] = useState<PreferencesState>({ loading: true });
    //subscribe
    const reload = async () => {
        const preferences = await vaultService.getPreferences();
        setState((state) => ({ ...state, preferences, loading: false }));
    }
    useEffect(() => {
        reload();
    }, []);
    const getProvider = (provider: Provider) => {
        return providers.find(e => e.provider === provider);
    }
    const { loading, preferences } = state;
    if (loading) {
        return <Dashboard active="accounts" className="max-w800">
            <Loader />
        </Dashboard>
    }
    const { accounts, registration, twoFactor } = preferences!
    const sxDivivder = {
        '&': {
            height: 'auto !important;'
        }
    }
    return <Dashboard active="preferences" className="max-w800">
        <MDBContainer className='d-flex gap-4 my-4' fluid>
            <div className='d-flex gap-4 flex-column flex-grow-1'>
                <div className="d-flex gap-2 flex-column">
                    <small>{texts.security}</small>
                    <MDBListGroup tag='div'>
                        <MDBListGroupItem>
                            <InputSwitch labelText={texts.enable2FA} model={twoFactor.enabled} />
                        </MDBListGroupItem>
                    </MDBListGroup>
                </div>
                <div className="d-flex gap-2 flex-column">
                    <small>{texts.accounts}</small>
                    <MDBListGroup tag='div'>
                        {accounts.map((e,index) => {
                            const provider = getProvider(e.provider)!;
                            return <MDBListGroupItem key={index} className="d-flex align-items-center gap-4">
                                <MDBIcon fab={provider.logoType === "fab"} fas={provider.logoType === "fas"} icon={provider.logo} size="2x" style={{ color: provider.logoColor, width: 32, textAlign: "center" }} />
                                <span>{e.name}</span>
                            </MDBListGroupItem>
                        })}
                    </MDBListGroup>
                </div>
            </div>
            <Divider orientation='vertical' sx={sxDivivder} className="my-2" />
            <div className='d-flex gap-4 flex-column flex-grow-1'>
                <div className="d-flex gap-2 flex-column">
                    <div className="d-flex justify-content-between align-items-baseline">
                        <small>{texts.registration}</small>
                        <a className="text-warning lh-1 d-flex align-items-center gap-2" href={routes.renew}>{texts.renew}<MDBIcon icon="reply"></MDBIcon></a>
                    </div>
                    <MDBListGroup tag='div'>
                        <MDBListGroupItem className="d-flex align-items-center justify-content-between">
                            <label>{texts.validUntil}</label>
                            <small>{StringUtils.formatDateTime(registration.validUntil)}</small>
                        </MDBListGroupItem>
                    </MDBListGroup>
                </div>
                <div className="d-flex gap-2 flex-column">
                    <small>{texts.payments}</small>
                    <MDBListGroup tag='div'>
                        {registration.payments.map((e,index) => {
                            return <MDBListGroupItem key={index} className="d-flex align-items-center justify-content-between">
                               <label>{e.amountOfYear} {texts.years} ({e.amountPaid}{e.symbol})</label>
                               <small>{StringUtils.formatDateTime(e.payOn)}</small>
                            </MDBListGroupItem>
                        })}
                    </MDBListGroup>
                </div>
            </div>
        </MDBContainer>
    </Dashboard>
}