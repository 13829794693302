import { ChangeEvent, useEffect, useState } from "react";
import { FormControl, ListGroup, Modal, Form, Spinner } from "react-bootstrap";
import { providers } from "../constants/constants";
import { texts } from "../constants/text";
import { ProviderInfo, Provider } from "../services";
import ProviderIcon from "../ui/ProviderIcon";

type ModalProvidersState = {
    original?: ProviderInfo[],
    filtered?: ProviderInfo[]
    loading: boolean
}

type ModalProvidersProps = {
    show: boolean,
    title?: string,
    hideSearch?: boolean,
    onSelect(asset?: Provider): void,
    filter?(asset?: Provider): boolean
}

export default function ModalProviders(props: ModalProvidersProps) {
    const [state, setState] = useState<ModalProvidersState>({ loading: true });
    useEffect(() => {
        const exec = async () => {
            const original = providers.slice();
            const tmp = [...original ];
            const assets: ProviderInfo[] = tmp.filter(e => {
                if (props.filter) {
                    return props.filter(e.provider)
                }
                return true;
            });
            setState({ original: assets, filtered: assets, loading: false });
        }
        exec();
    }, [props])
    const search = (e: ChangeEvent) => {
        let searchVal = ((e.target as any).value as string).toLowerCase();
        const filtered = state.original?.filter(e => e.name?.toLowerCase().indexOf(searchVal) > -1)
        setState({ ...state, filtered });
    }
    const title = props.title || texts.selectProvider;
    return <Modal centered show={props.show} contentClassName="p-0 rad-20" dialogClassName="max-w400" onHide={() => props.onSelect(undefined)}>
        <Modal.Header className="d-flex flex-column align-items-stretch">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                <ListGroup.Item as="li" className="d-flex flex-column justify-content-between text-center p-2">
                    <Form.Label>{title}</Form.Label>
                    {!props.hideSearch && <FormControl placeholder={texts.search} onChange={search} className="rad-16" />}
                </ListGroup.Item>
            </ListGroup>
        </Modal.Header>
        <Modal.Body className="p-0 max-h50v overflow-scroll">
            <ListGroup as="ol" className="list-group-flush pointer px-0">
                {state.filtered?.map((a, index) => {
                    return <ListGroup.Item key={index} action as="li" className="d-flex align-items-center justify-content-between p-3 gap-3" onClick={(_e) => props.onSelect(a.provider)}>
                         <ProviderIcon provider={a} size="2x" minWidth={40} />
                        <p className="flex-grow-1 m-0"><b>{a.name}</b></p>
                    </ListGroup.Item>;
                })}
                {state.loading && <ListGroup.Item action as="li" className="d-flex align-items-center justify-content-between p-3" >
                    <Spinner as="span" animation="grow" role="status" aria-hidden="true" className="me-1" />
                </ListGroup.Item>}
            </ListGroup>
        </Modal.Body>
        <Modal.Footer className="border-0"></Modal.Footer>
    </Modal>
}