import React, { } from 'react';
import { MDBSwitch } from "mdb-react-ui-kit";

export type InputSwitchProps = {
    labelText?: string
    required?:boolean
    model :boolean
    onChange?(value:boolean):void
}

export type InputSwitchState = {
}

export default function InputSwitch({  required,labelText, model, onChange:onChangeOrig }: InputSwitchProps) {
    const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        onChangeOrig && onChangeOrig(e.target.checked)
    }
    return <MDBSwitch label={labelText} required={required} checked={model} onChange={onChange}/>
}