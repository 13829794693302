import { texts } from "../constants/text";
import InputSubmit from "../ui/InputSubmit";
import InputText from "../ui/InputText";
import Panel from "../ui/Panel";
import { FileUpload } from "../upload/FileUpload";



export default function NFTMint() {
    const onBack = () => {

    }
    return <Panel icon="circle-plus" className="max-w480" bodyClassName="gap-3" title={texts.mintNft} onBack={onBack}>
        <FileUpload removeBtn={texts.remove} defaultMsg={texts.uploadImage} acceptedExtensions={["image/*"]} />
        <InputText labelText={texts.title}/>
        <InputText labelText={texts.description} rows={3}/>
        <InputSubmit labelText={texts.mintNft}/>
    </Panel>
}