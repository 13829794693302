import { BigNumber, BigNumberish } from "ethers";

export const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export class DateUtils {
    static fromBigNumber(bignumberish: BigNumberish){
        const tmp = BigNumber.from(bignumberish);
        return new Date(tmp.toNumber()*1000)
    }
    static addMinutes(time:number, minutes:number){
        return time + (minutes * 60);
    }

    static addHours(time:number, hours:number){
        return time + (hours * 60 * 60);
    }

    static addDays(time:number|Date, days:number){
        if(time instanceof Date){
            time = time.getTime();
        }
        return time + (days * 60 * 60 * 24);
    }

    static addDaysD(time:number|Date, days:number){
        return new Date(DateUtils.addDays(time,days))
    }

    static now(){
        return Math.round(new Date().getTime() / 1000);
    }  

    static nowSeconds(){
        return Math.round(new Date().getTime() / 1000);
    }  

    static toSeconds(date:Date){
        return date.getTime() / 1000;
    }  

    static fromSeconds(sec:number | BigNumber){
        if(sec instanceof BigNumber){
            return new Date(sec.mul(1000).toNumber())
        }
        return new Date(sec*1000);
    }    
}

