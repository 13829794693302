import React, { } from 'react';
import TextField from '@mui/material/TextField';


export type InputDateProps = {
    labelText?: string
    placeholder?:string
    required?:boolean
    type:"date"|"time"|"datetime"
}

export type InputDateState = {
}

export default function InputDate({ type, required,labelText, placeholder }: InputDateProps) {
    return <>
        <TextField
        size='small'
        label={labelText}
        placeholder={placeholder}
        required={required}
        type={type === "datetime"? "datetime-local": type}
        defaultValue="2017-05-24T10:30"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
}