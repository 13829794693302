import { MDBCardTitle, MDBBtn, MDBIcon, MDBContainer, MDBCollapse } from 'mdb-react-ui-kit';
import { useState } from 'react';
import { texts } from '../constants/text';
import GasPrice from '../ui/GasPrice';
import InputCurrency from '../ui/InputCurrency';
import InputSubmit from '../ui/InputSubmit';
import Panel from '../ui/Panel';
import SwapPrice from './SwapPrice';
import SwapPriceDetails from './SwapPriceDetail';
import SwapSettings from './SwapSettings';

export type SwapState = {
    showSettings?: boolean
    showDetails?: boolean
}

export default function Swap() {
    const onBack = () =>{}
    const [state, setState] = useState<SwapState>({});
    const onToggleDetails = () => {
        setState((state) => ({ ...state, showDetails: !state.showDetails }))
    }
    const onToggleSettings = () => {
        setState((state) => ({ ...state, showSettings: !state.showSettings }))
    }
    const { showDetails, showSettings } = state;
    return <>
        <Panel onBack={onBack} title={texts.swap} icon='exchange' className='max-w480 gap-1' bodyClassName='gap-2'>
            <MDBCardTitle className='d-flex align-items-center justify-content-between m-0' tag='div'>
                <MDBBtn tag='a' color='light' floating><MDBIcon fas icon='refresh' /></MDBBtn>
                <p className='m-0'>{texts.swapSubtitle}</p>
                <MDBBtn onClick={onToggleSettings} tag='a' color='light' floating><MDBIcon fas icon='gear' /></MDBBtn>
            </MDBCardTitle>
            <MDBContainer className='d-flex flex-column gap-3 my-4 px-0'>
                <InputCurrency labelText={texts.fromCurrency} />
                <MDBBtn className='align-self-center' tag='a' color='light' floating><MDBIcon fas icon='right-left' className='rotate-90' /></MDBBtn>
                <InputCurrency labelText={texts.toCurrency} />
                <div className='d-flex justify-content-between gap-2'>
                    <SwapPrice vertical onClick={onToggleDetails} />
                    <GasPrice vertical />
                </div>
                <MDBCollapse show={showDetails}><SwapPriceDetails /></MDBCollapse>
                <InputSubmit labelText={texts.connectWallet} />
            </MDBContainer>
        </Panel>
        <SwapSettings onClose={onToggleSettings} show={showSettings!} />
    </>
}