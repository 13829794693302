import * as React from "react";
import { DataGrid, DataGridProps,GridRenderCellParams, GridColumns, GridRow, GridRowProps, GridRowsProp,GridCallbackDetails,GridSelectionModel } from '@mui/x-data-grid';
export type { GridColumns, GridRow,GridRenderCellParams, DataGridProps, GridRowProps,GridRowsProp,GridCallbackDetails,GridSelectionModel };
export default function TableMUI(props: DataGridProps) {
    /*
    2021-01-08
    Work around MUI DataGrid issue that sets `height: 0px;` when autoHeight is enabled 
    https://github.com/mui-org/material-ui-x/issues/604
    
    Get the first div (which is the MUI datagrid element) and clear the 0px CSS height style
    */
    const gridWrapperRef = React.useRef<HTMLDivElement>(null);
    React.useLayoutEffect(() => {
        const gridDiv = gridWrapperRef.current;
        if (gridDiv) {
            gridDiv.style.height = '';
        }
    });

    return (
        <div ref={gridWrapperRef}>
            <DataGrid
                {...props}
                autoHeight={true}
            />
        </div>
    );
}